import lodash from 'lodash';

import * as actionTypes from '../actionTypes';

const initialState = {
};

function setFaqSelect(state, action) {
  const { data, type } = action.payload;
  let selectedIndex = data;

  // collapse current card => set index to -1 : collapse all
  if (selectedIndex === state.faq[type].selectedIndex) {
    selectedIndex = -1;
  }

  return {
    ...state,
    faq: {
      ...state.faq,
      [type]: {
        ...state.faq[type],
        selectedIndex: selectedIndex,
      },
    },
  };
}

function setProductFlow(state, action) {
  const selected = action.payload.selection;

  return {
    ...state,
    productSwitch: {
      ...state.productSwitch,
      selected: selected,
    },
  };
}

function updateProductForm(state, action) {
  return {
    ...state,
    productIntro: {
      ...state.productIntro,
      [action.payload.id]: {
        ...state.productIntro[action.payload.id],
        check: {
          ...state.productIntro[action.payload.id].check,
          [action.payload.field]: action.payload.payload,
        },
      },
    },
  };
}

function setComponentSwitch(state, action) {
  const { data, id } = action.payload;
  return {
    ...state,
    componentSwitch: {
      ...state.componentSwitch,
      [id]: {
        ...state.componentSwitch[id],
        selected: data,
      },
    },
  };
}

function setAPItabCode(state, action) {
  const { data, id } = action.payload;

  return {
    ...state,
    sideNav: {
      ...state.sideNav,
      [id]: {
        ...state.sideNav[id],
        selected: data,
      },
    },
  };
}

function setEmployeeCarousel(state, action) {
  const { id } = action.payload;
  const carouselSize = state.employeeCarousel[id].employees.length;
  let selectedIndex = state.employeeCarousel[id].selected;

  selectedIndex = selectedIndex === carouselSize - 1 ? 0 : selectedIndex + 1;

  return {
    ...state,
    employeeCarousel: {
      ...state.employeeCarousel,
      [id]: {
        ...state.employeeCarousel[id],
        selected: selectedIndex,
      },
    },
  };
}

function updateContactForm(state, action) {
  return {
    ...state,
    contact: {
      ...state.contact,
      [action.payload.form]: {
        ...state.contact[action.payload.form],
        form: {
          ...state.contact[action.payload.form].form,
          [action.payload.id]: action.payload.val,
        },
      },
    },
  };
}

function setLogoAsset(state, action) {
  let selected = state.assetLogoContent.logos.selected;
  return {
    ...state,
    assetLogoContent: {
      ...state.assetLogoContent,
      logos: {
        ...state.assetLogoContent.logos,
        selected: 1 - selected,
      },
    },
  };
}

function setPostName(state, action) {
  let newSelectedTitle = action.payload.title;
  let type = action.payload.type;

  return {
    ...state,
    posts: {
      ...state.posts,
      selectedTitle: newSelectedTitle,
      type: type,
    },
  };
}

function setWebsiteState(state, action) {
  let newState = lodash.cloneDeep(action.payload);
  return {
    ...newState,
    vertical: state.vertical,
  };
}

function setVertical(state, action) {
  return {
    ...state,
    vertical: action.payload,
  };
}

const blacklist = [
  'TOGGLE_LOGO_ASSET',
  'SET_POST_TITLE',
  'SWITCH_SIDE_NAV',
];

export default (state = initialState, action) => {
  if (!blacklist.includes(action.type) && !action.type.startsWith('@@redux')) {
    action.timestamp = new Date() / 1000;
  }

  switch (action.type) {
    case actionTypes.TOGGLE_FAQ:
      return setFaqSelect(state, action);
    case actionTypes.SELECT_PRODUCT_FLOW:
      return setProductFlow(state, action);
    case actionTypes.PREV_PRODUCT_FLOW:
      return setProductFlow(state, action);
    case actionTypes.SWITCH_COMPONENT:
      return setComponentSwitch(state, action);
    case actionTypes.SWITCH_SIDE_NAV:
      return setAPItabCode(state, action);
    case actionTypes.SWITCH_EMPLOYEE_CAROUSEL:
      return setEmployeeCarousel(state, action);
    case actionTypes.UPDATE_PRODUCT_FORM:
      return updateProductForm(state, action);
    // case actionTypes.SET_CB_CUSTOMIZATION:
    //   return setCBCustomization(state, action);
    case actionTypes.TOGGLE_LOGO_ASSET:
      return setLogoAsset(state, action);
    case actionTypes.SET_POST_TITLE:
      return setPostName(state, action);
    case actionTypes.SET_WEBSITE_STATE:
      return setWebsiteState(state, action);
    case actionTypes.SET_VERTICAL:
      return setVertical(state, action);
    case actionTypes.UPDATE_CONTACT_FORM:
      return updateContactForm(state, action);

    default:
      return state;
  }
}
