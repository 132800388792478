// for the faq items
export const TOGGLE_FAQ = 'TOGGLE_FAQ';
// for the role switch (i need to ...) on product page (check, invoice)
export const SELECT_PRODUCT_FLOW = 'SELECT_PRODUCT_FLOW';
export const SWITCH_COMPONENT = 'SWITCH_COMPONENT';

export const SWITCH_EMPLOYEE_CAROUSEL = 'SWITCH_EMPLOYEE_CAROUSEL';
export const SWITCH_SIDE_NAV = 'SWITCH_SIDE_NAV';

export const UPDATE_PRODUCT_FORM = 'UPDATE_PRODUCT_FORM';
export const PREV_PRODUCT_FLOW = 'PREV_PRODUCT_FLOW';

export const TOGGLE_LOGO_ASSET = 'TOGGLE_LOGO_ASSET';
export const SET_POST_TITLE = 'SET_POST_TITLE';

export const LAND_ON_PAGE = 'LAND_ON_PAGE';
export const LEAVE_SITE = 'LEAVE_SITE';

export const SET_WEBSITE_STATE = 'SET_WEBSITE_STATE';
export const SET_VERTICAL = 'SET_VERTICAL';
export const SET_MODAL = 'SET_MODAL';
export const UPDATE_CONTACT_FORM = 'UPDATE_CONTACT_FORM';
