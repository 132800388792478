import * as actionTypes from '../actionTypes';

const initialState = {
  modalToggle: false,
  modalComponent: { component: 'table', id: 'features' },
};

function setModal(state, action) {
  return {
    ...state,
    modalToggle: Boolean(action.payload),
    modalComponent: action.payload,
  };
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MODAL:
      return setModal(state, action);

    default:
      return state;
  }
}
